import { env } from 'infra/env';
import { NextRequest } from 'next/server';
import { parseCookies } from 'nookies';
import { ServerRequestContext } from 'types/others';

export function getUserJwtToken(ctx: ServerRequestContext): string | undefined {
  if (ctx instanceof NextRequest) {
    return ctx.cookies.get(env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME)?.value;
  }

  const parsedCookies = parseCookies(ctx);

  return parsedCookies[env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME];
}
