import { getUserJwtToken } from '@cinesj/admin/core/auth';
import { env } from '@cinesj/admin/infra/env';
import { AuthTokenType, CinesjClient } from '@cinesj/sdk';
import { GetServerSidePropsContext } from 'next';

export function createClient(ctx?: GetServerSidePropsContext) {
  return new CinesjClient({
    apiEndpoint: env.NEXT_PUBLIC_API_URL,
    auth: {
      async getAuthToken() {
        const token = getUserJwtToken(ctx);

        if (!token) {
          return undefined;
        }

        return {
          type: AuthTokenType.jwt,
          token,
        };
      },
    },
  });
}

export const client = createClient();
