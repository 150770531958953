import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    NODE_ENV: z.enum(['development', 'production', 'test']),
    API_JWT_PUBLIC_KEY: z.string().min(1),
    API_JWT_ALGORITHM: z.enum(['RS256', 'RS384', 'RS512']),
  },
  client: {
    NEXT_PUBLIC_UMAMI_SCRIPT_URL: z.string().url().min(1).optional(),
    NEXT_PUBLIC_UMAMI_WEBSITE_ID: z.string().uuid().min(1).optional(),
    NEXT_PUBLIC_API_URL: z.string().url().min(1),
    NEXT_PUBLIC_FLAGSMITH_ENVIRONMENT_ID: z.string().min(1),
    NEXT_PUBLIC_FLAGSMITH_API_URL: z.string().url().min(1),
    NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME: z
      .string()
      .min(1)
      .default('cinesj.admin.auth.token'),
    NEXT_PUBLIC_JWT_TOKEN_COOKIE_TTL: z.string().min(1).default('12m'),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    API_JWT_ALGORITHM: process.env.API_JWT_ALGORITHM,
    API_JWT_PUBLIC_KEY: process.env.API_JWT_PUBLIC_KEY,
    NEXT_PUBLIC_UMAMI_SCRIPT_URL: process.env.NEXT_PUBLIC_UMAMI_SCRIPT_URL,
    NEXT_PUBLIC_UMAMI_WEBSITE_ID: process.env.NEXT_PUBLIC_UMAMI_WEBSITE_ID,
    NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
    NEXT_PUBLIC_JWT_TOKEN_COOKIE_TTL:
      process.env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_TTL,
    NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME:
      process.env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME,
    NEXT_PUBLIC_FLAGSMITH_ENVIRONMENT_ID:
      process.env.NEXT_PUBLIC_FLAGSMITH_ENVIRONMENT_ID,
    NEXT_PUBLIC_FLAGSMITH_API_URL: process.env.NEXT_PUBLIC_FLAGSMITH_API_URL,
  },
});
