'use client';

import '@cinesj/web-ui/styles.css';
import '@cinesj/web-ui/omni-router/next';

import { ReactNode } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { useInitializeAuthState } from '@cinesj/admin/core/auth';
import { ToastContainer } from '@cinesj/admin/core/notifications';
import { queryClient } from '@cinesj/admin/infra/query';
import { CinesjProvider, cinesjTheme } from '@cinesj/web-ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export type ProvidersProps = {
  children: ReactNode;
};

export function Providers({ children }: ProvidersProps) {
  useInitializeAuthState();

  return (
    <ChakraProvider theme={cinesjTheme}>
      <CinesjProvider>
        <QueryClientProvider client={queryClient}>
          {children}
          <ToastContainer />
          <ReactQueryDevtools />
        </QueryClientProvider>
      </CinesjProvider>
    </ChakraProvider>
  );
}
