import { useEffectOnce } from '@cinesj/hooks';

import { useAuthStore } from '../store';

export function useInitializeAuthState() {
  const initialize = useAuthStore(state => state.initialize);

  useEffectOnce(() => {
    initialize().then();
  });
}
