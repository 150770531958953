import { client } from '@cinesj/admin/infra/client';
import { UserRole } from '@cinesj/models';
import { GetMeCommand, LoginCommand } from '@cinesj/sdk';
import { create } from 'zustand';

import type { AuthState, AuthStateProps } from './types';
import { destroyUserJwtToken, setUserJwtToken } from './utils';

const initialState: AuthStateProps = {
  user: null,
  isAuthenticated: false,
};

export const authStore = create<AuthState>((set, get) => ({
  ...initialState,
  async initialize() {
    try {
      const { logout } = get();
      const user = await client.run(new GetMeCommand());

      if (user.role !== UserRole.Admin) {
        await logout();
        return;
      }

      set({
        user,
        isAuthenticated: true,
      });
    } catch (error) {
      set(initialState);
    }
  },
  async login(props) {
    const login = await client.run(new LoginCommand(props));

    if (login.user.role !== UserRole.Admin) {
      throw new Error('You are not an admin');
    }

    setUserJwtToken(undefined, login.token.jwt);

    set({
      user: login.user,
      isAuthenticated: true,
    });
  },
  async logout() {
    destroyUserJwtToken(undefined);
    window.location.reload();
    set(initialState);
  },
}));

export const useAuthStore = authStore;
