import { getUserJwtToken } from '@cinesj/admin/core/auth';
import { destroyUserJwtToken } from '@cinesj/admin/core/auth/utils';
import { env } from '@cinesj/admin/infra/env';
import { AuthJwtToken, JwtPayload, UserRole } from '@cinesj/models';
import { importSPKI, jwtVerify } from 'jose';
import { NextRequest, NextResponse } from 'next/server';

function onUnauthorized(req: NextRequest) {
  const returnUrl = encodeURIComponent(req.nextUrl.href);

  const res = NextResponse.redirect(
    new URL(`/login?returnUrl=${returnUrl}`, req.nextUrl),
  );
  destroyUserJwtToken(res);

  return res;
}

export async function middlewareAuthGuard(req: NextRequest) {
  try {
    const userToken = getUserJwtToken(req);

    if (!userToken) {
      return onUnauthorized(req);
    }

    const key = await importSPKI(
      env.API_JWT_PUBLIC_KEY,
      env.API_JWT_ALGORITHM,
      {
        extractable: true,
      },
    );
    const { payload } = await jwtVerify<JwtPayload<AuthJwtToken>>(
      userToken,
      key,
    );

    if (!payload) {
      return onUnauthorized(req);
    }

    if (payload.role !== UserRole.Admin) {
      return onUnauthorized(req);
    }

    return undefined;
  } catch (error) {
    return onUnauthorized(req);
  }
}
