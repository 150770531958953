import { toast } from './provider';
import type { ErrorNotificationData, SuccessNotificationData } from './types';

export abstract class Notifications {
  public static success({
    title = 'Sucesso!',
    message,
    status = 'success',
    ...rest
  }: SuccessNotificationData) {
    toast({
      title,
      status,
      description: message,
      ...rest,
    });
  }

  public static error({
    title = 'Ops!',
    message,
    status = 'error',
    ...rest
  }: ErrorNotificationData) {
    toast({
      title,
      status,
      description: message,
      ...rest,
    });
  }
}
