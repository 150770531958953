import { env } from 'infra/env';
import { NextResponse } from 'next/server';
import { destroyCookie } from 'nookies';
import { ServerResponseContext } from 'types/others';

export function destroyUserJwtToken(ctx: ServerResponseContext) {
  if (ctx instanceof NextResponse) {
    ctx.cookies.delete(env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME);
    return;
  }

  destroyCookie(ctx, env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME);
}
