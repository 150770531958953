'use client';

import { createStandaloneToast, CreateToastFnReturn } from '@chakra-ui/toast';
import { cinesjTheme } from '@cinesj/web-ui';

const standaloneToast = createStandaloneToast({
  theme: cinesjTheme,
  defaultOptions: {
    isClosable: true,
  },
});

export const { ToastContainer } = standaloneToast;
export function toast(options: Parameters<CreateToastFnReturn>[0]) {
  return standaloneToast.toast(options);
}
