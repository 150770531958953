import { env } from 'infra/env';
import ms from 'ms';
import { NextResponse } from 'next/server';
import { setCookie } from 'nookies';
import { ServerResponseContext } from 'types/others';

export function setUserJwtToken(ctx: ServerResponseContext, token: string) {
  if (ctx instanceof NextResponse) {
    ctx.cookies.set({
      name: env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME,
      value: token,
      maxAge: ms(env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_TTL),
      path: '/',
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
    });
    return;
  }

  setCookie(ctx, env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME, token, {
    maxAge: ms(env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_TTL),
    path: '/',
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
  });
}
